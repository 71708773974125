<template>
  <Table
    :columns="columns"
    :data="fileTransactionsToMatch"
    :cell-class="selectedRow"
    :hover="!disabled"
    :class="{ ['table-focus-shadow border-primary']: showOutlineSelected }"
    custom-class="transactions-table"
    border
    rounded
    @row-click="handleRowClick"
  >
    <template #cell-date="{ rowData: { date } }">
      {{ formatDate(date) }}
    </template>
    <template #cell-valueDate="{ rowData: { valueDate } }">
      {{ formatDate(valueDate) }}
    </template>
  </Table>
</template>

<script>
import { Table } from '@/modules/core';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { formatDate } from '../../../tools/formatters';

export const TABLE_HEADER = {
  DATE: 'date',
  VALUE_DATE: 'valueDate',
  DESCRIPTION: 'description',
  REFERENCE: 'reference',
  DEBIT: 'debit',
  NOTES: 'notes',
};

export default {
  components: { Table },
  props: {
    fileTransactions: { type: Array, required: true },
    selectedFileTransactionId: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    showOutlineSelected: { type: Boolean, default: false },
  },
  setup() {
    const { formatToCurrency } = useCurrency();

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    return { formatMoney };
  },
  computed: {
    columns() {
      return [
        {
          header: this.translate('date'),
          key: TABLE_HEADER.DATE,
          width: '100px',
        },
        {
          header: this.translate('valueDate'),
          key: TABLE_HEADER.VALUE_DATE,
          width: '100px',
        },
        {
          header: this.translate('description'),
          key: TABLE_HEADER.DESCRIPTION,
          width: '140px',
        },
        {
          header: this.translate('reference'),
          key: TABLE_HEADER.REFERENCE,
          width: '120px',
        },
        {
          header: this.translate('debit'),
          key: TABLE_HEADER.DEBIT,
          width: '130px',
        },
        {
          header: this.translate('notes'),
          key: TABLE_HEADER.NOTES,
          width: '100px',
        },
      ];
    },
    fileTransactionsToMatch() {
      return this.fileTransactions.map((t) => {
        return {
          ...t,
          [TABLE_HEADER.DEBIT]: this.formatMoney(t[TABLE_HEADER.DEBIT]),
        };
      });
    },
    selectedIndex() {
      return this.fileTransactionsToMatch.findIndex((t) => t.id === this.selectedFileTransactionId);
    },
  },
  methods: {
    translate(key) {
      return this.$t(`payment.paymentMatchingModal.bankAccountTransactions.table.${key}`);
    },
    formatDate,
    selectedRow(rowIndex) {
      if (rowIndex === this.selectedIndex) return 'bg-selected';
    },
    handleRowClick(rowIndex) {
      const rowId = this.fileTransactionsToMatch[rowIndex].id;
      const isSelected = this.selectedFileTransactionId === rowId;
      this.$emit('update:selectedFileTransactionId', !isSelected ? rowId : null);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

::v-deep .transactions-table {
  tr:hover td.bg-selected {
    background: change-color($color: $surfaces-selected, $lightness: 90%) !important;
  }
}
</style>
