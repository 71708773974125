<template>
  <el-dialog
    visible
    append-to-body
    :show-close="false"
    :before-close="handleClose"
    custom-class="rounded payments-reconciliation-modal bg-light  mt-5 mb-0"
  >
    <template #title>
      <div class="p-4 bg-white rounded-top border-bottom d-flex justify-content-between">
        <h1>{{ translate('title') }}</h1>
        <Button type="icon" class="p-0" @click="handleClose">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div v-loading="loading" class="p-4 d-flex justify-content-between h-100 position-relative">
      <div class="h-100 d-flex flex-column match-side">
        <h3 class="mb-5">{{ translate('paymentsToMatch.header') }}</h3>
        <PaymentsToMatch
          :payment-groups="paymentGroups"
          :suppliers="suppliers"
          :matched-map="matchedMap"
          :selected-payment-indicator.sync="selectedPaymentIndicator"
          :disabled="UiState === STATES.UPLOAD_FILE"
          :show-outline-selected="UiState === STATES.PICK_PAYMENT"
          class="h-auto"
        />
        <div class="text-center mt-auto pt-4">
          <p :class="{ ['d-none']: UiState !== STATES.PICK_PAYMENT }">{{ translate('select.payment') }}</p>
        </div>
      </div>
      <div class="h-100 d-flex flex-column match-side">
        <h3 class="mb-5">{{ translate('bankAccountTransactions.header') }}</h3>
        <BankAccountTransactions
          v-if="UiState !== STATES.UPLOAD_FILE"
          :file-transactions="fileTransactionsRelatedToPayments"
          :selected-file-transaction-id.sync="selectedFileTransactionId"
          :disabled="UiState === STATES.PICK_PAYMENT || UiState === STATES.UNMATCH"
          :show-outline-selected="UiState === STATES.PICK_FILE_TRANSACTION"
          class="h-auto"
        />

        <el-upload
          v-if="UiState === STATES.UPLOAD_FILE"
          class="file-transactions-upload h-100 d-flex flex-column match-side p-1"
          drag
          action="#"
          :before-upload="handleUploadFile"
          :show-file-list="false"
        >
          <p class="mb-4 text-typography-secondary">{{ translate('uploadTransactionsFile.header') }}</p>
          <Button :style="{ padding: '3px' }" :class="$direction === 'rtl' ? 'ps-2' : 'pe-2'">
            <UploadIcon />
            {{ translate('uploadTransactionsFile.upload') }}
          </Button>
        </el-upload>

        <div class="text-center mt-auto pt-4">
          <p :class="{ ['d-none']: UiState !== STATES.PICK_FILE_TRANSACTION }">
            {{ translate('select.transaction') }}
          </p>
        </div>
      </div>
      <div
        class="position-absolute"
        :style="{ bottom: '1rem', left: `calc(50% - (${$direction === 'rtl' ? '79px' : '77px'} / 2))` }"
      >
        <Button
          v-if="isMatchUnmatchState"
          type="secondary"
          :class="`p-1 text-typography-primary ${$direction === 'rtl' ? 'ps-2' : 'pe-2'}`"
          @click="handleMatch"
        >
          <LinkIcon v-if="UiState === STATES.MATCH" />
          <UnlinkIcon v-else />
          {{ translate(UiState) }}
        </Button>
      </div>
    </div>
    <template #footer>
      <div class="bg-white p-4 rounded-bottom">
        <Button :disabled="loading" type="secondary" @click="handleClose">{{ $t('commons.cancel') }}</Button>
        <Button :disabled="loading" @click="handleSave">{{ $t('commons.save') }}</Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { ref, computed } from 'vue';
import XLSX from 'xlsx';
import { DateTime } from 'luxon';
import { omit, isNil, isEmpty, reject, anyPass } from 'ramda';

import { CloseIcon, UploadIcon, LinkIcon, UnlinkIcon } from '@/assets/icons';
import { Button } from '@/modules/core';
import { useTenancy } from '@/modules/auth';

import PaymentsToMatch from './PaymentsToMatch';
import BankAccountTransactions, { TABLE_HEADER } from './BankAccountTransactions.vue';
import { useBankAccounts } from '../../../compositions';

const STATES = {
  UPLOAD_FILE: 'uploadFile',
  PICK_PAYMENT: 'pickPayment',
  PICK_FILE_TRANSACTION: 'pickFileTransaction',
  MATCH: 'match',
  UNMATCH: 'unmatch',
};

const getFileTransactions = (xlsxAsArray, tenantBankNumber) => {
  const fileWithNoEmptyLines = xlsxAsArray.filter((cols) => cols.length && !(cols.length === 1 && cols[0] === 'N/A'));
  const bankNumber = determineBankNumber(fileWithNoEmptyLines);
  if (!bankNumber || tenantBankNumber !== bankNumber) return;
  return bankNumber === '12'
    ? handleBankHaPoalim(fileWithNoEmptyLines)
    : bankNumber === '11'
    ? handleBankDiscount(fileWithNoEmptyLines)
    : null;
};

const determineBankNumber = (xlsxAsArray) => {
  const haPoalimHeaders = xlsxAsArray[2];
  const discountHeaders = xlsxAsArray[5];
  const isHaPoalimBank = haPoalimHeaders[0] === 'תאריך' && haPoalimHeaders[6] === 'חובה';
  const isDiscountBank = discountHeaders[0] === 'תאריך' && discountHeaders[3] === '₪ זכות/חובה ';
  return isHaPoalimBank ? '12' : isDiscountBank ? '11' : null;
};

const handleBankHaPoalim = (xlsxAsArray) => {
  const debitIndex = '6';
  const columnsToKeysMap = {
    0: TABLE_HEADER.DATE,
    2: TABLE_HEADER.DESCRIPTION,
    4: TABLE_HEADER.REFERENCE,
    [debitIndex]: TABLE_HEADER.DEBIT,
  };

  const removeCreditTransactions = reject((row) => anyPass([isNil, isEmpty])(row[debitIndex]));

  // index 4 is where the transactions start, 3 first rows (indexes) are title, bank details and transactions headers
  const SLICE_ONLY_TRANSACTIONS_INDEX = 3;
  let transactions = xlsxAsArray.slice(SLICE_ONLY_TRANSACTIONS_INDEX);

  const rows = removeCreditTransactions(transactions);

  return buildTransactionsData(rows, columnsToKeysMap);
};

const handleBankDiscount = (xlsxAsArray) => {
  const debitIndex = '3';
  const columnsToKeysMap = {
    0: TABLE_HEADER.DATE,
    1: TABLE_HEADER.VALUE_DATE,
    2: TABLE_HEADER.DESCRIPTION,
    5: TABLE_HEADER.REFERENCE,
    [debitIndex]: TABLE_HEADER.DEBIT,
  };

  const removeCreditTransactions = reject((row) => row[debitIndex] >= 0);

  // index 9 is where the transactions start, 6 first rows (indexes) are title, bank details and transactions headers
  const SLICE_ONLY_TRANSACTIONS_INDEX = 6;
  let transactions = xlsxAsArray.slice(SLICE_ONLY_TRANSACTIONS_INDEX);
  const indexOfFutureTransactions = transactions.findIndex((array) => array[0] === 'תנועות עתידיות');
  if (indexOfFutureTransactions !== -1) {
    transactions = transactions.slice(0, indexOfFutureTransactions);
  }
  const rows = removeCreditTransactions(transactions);

  return buildTransactionsData(rows, columnsToKeysMap);
};

const dataTransformer = (key, index, row) => {
  const data = row[index];
  switch (key) {
    case TABLE_HEADER.DATE:
    case TABLE_HEADER.VALUE_DATE:
      return data ? new Date(data).getTime() : null;
    case TABLE_HEADER.DESCRIPTION: {
      const text = [data, row[index + 1]].filter((t) => !isNil(t)).join(' ');
      return text;
    }
    case TABLE_HEADER.DEBIT:
      return Math.abs(data);
    case TABLE_HEADER.REFERENCE:
      return data.toString();
    default:
      return data;
  }
};

const buildTransactionsData = (rows, columnsToKeysMap) =>
  rows.map((row, index) => {
    const fileTransaction = Object.entries(columnsToKeysMap).reduce(
      (newFileTransaction, [columnIndex, dataKey]) => ({
        ...newFileTransaction,
        [dataKey]: dataTransformer(dataKey, Number(columnIndex), row),
      }),
      {}
    );
    return {
      ...fileTransaction,
      id: `${fileTransaction.reference}_${fileTransaction.date}_${index}`,
    };
  });

export default {
  components: {
    CloseIcon,
    UploadIcon,
    LinkIcon,
    UnlinkIcon,
    Button,
    PaymentsToMatch,
    BankAccountTransactions,
  },
  props: {
    payments: { type: Array, default: () => [] },
    suppliers: { type: Array, default: () => [] },
  },
  setup() {
    const { currentTenant } = useTenancy();
    const { bankAccounts: tenantBankAccounts } = useBankAccounts(computed(() => currentTenant.value?.id));
    const tenantBankAccount = computed(() => tenantBankAccounts.value[0]);

    return {
      fileTransactions: ref(null),
      matchedMap: ref({}),
      selectedPaymentIndicator: ref(null),
      selectedFileTransactionId: ref(null),
      loading: ref(false),
      tenantBankAccount,
      STATES,
    };
  },
  computed: {
    paymentGroups() {
      const groupedByInstructionNumber = this.payments.reduce((instructionNumberMap, payment) => {
        const instructionNumber = payment.transactions[0].instructionNumber;
        const paymentWithDate = {
          ...payment,
          date: new Date(payment.date).getTime(),
        };
        if (!instructionNumberMap[instructionNumber]) instructionNumberMap[instructionNumber] = [paymentWithDate];
        else instructionNumberMap[instructionNumber].push(paymentWithDate);
        return instructionNumberMap;
      }, {});

      // sort payments and add summary item for payments group with more then one item
      for (const [instructionNumber, payments] of Object.entries(groupedByInstructionNumber)) {
        payments.sort((a, b) => a.date - b.date);
        if (payments.length > 1) {
          const paymentSummary = payments.reduce(
            (data, payment) => {
              const { supplierId, date, transactions } = payment;
              const accountNumber = transactions[0].destinationBankAccount?.accountNumber;

              data.accountNumbers[accountNumber] = accountNumber;
              data.suppliers[supplierId] = supplierId;
              data.amount += payment.amount;
              if (data.date < date) data.date = date;
              return data;
            },
            {
              suppliers: {},
              accountNumbers: {},
              amount: 0,
              date: 0,
            }
          );

          payments.unshift({
            id: `payments_group_id_${instructionNumber}`,
            instructionNumber,
            date: paymentSummary.date,
            suppliers: Object.keys(paymentSummary.suppliers).length,
            accountNumbers: Object.keys(paymentSummary.accountNumbers).length,
            amount: paymentSummary.amount,
          });
        }
      }

      return groupedByInstructionNumber;
    },
    matchedFileTransactions() {
      return Object.values(this.matchedMap).map(({ fileTransactionId }) => fileTransactionId);
    },
    fileTransactionsRelatedToPayments() {
      if (!this.selectedPaymentIndicator) return this.fileTransactions;

      const [instructionNumber, index] = this.selectedPaymentIndicator.split('-');

      const matched = this.matchedMap[this.selectedPaymentIndicator];
      if (matched) return [this.fileTransactions.find((t) => t.id === matched.fileTransactionId)];

      const selectedPayment = this.paymentGroups[instructionNumber][index];
      return this.fileTransactions.filter((t) => {
        if (selectedPayment) {
          const transactionDate = DateTime.fromMillis(t.date).startOf('day');
          const paymentDate = DateTime.fromMillis(selectedPayment.date).startOf('day');
          return t.debit === Number(selectedPayment.amount.toFixed(2)) && transactionDate >= paymentDate;
        }
        return !this.matchedFileTransactions.includes(t.id);
      });
    },
    isMatchUnmatchState() {
      return this.UiState === STATES.MATCH || this.UiState === STATES.UNMATCH;
    },
    UiState() {
      if (this.fileTransactions && !this.selectedPaymentIndicator) return STATES.PICK_PAYMENT;
      if (this.selectedPaymentIndicator && !this.selectedFileTransactionId) return STATES.PICK_FILE_TRANSACTION;
      if (this.selectedPaymentIndicator && this.selectedFileTransactionId) {
        const matched = this.matchedMap[this.selectedPaymentIndicator];
        if (matched) return STATES.UNMATCH;
        return STATES.MATCH;
      }
      return STATES.UPLOAD_FILE;
    },
  },
  watch: {
    selectedPaymentIndicator(indicator) {
      if (indicator) {
        const matched = this.matchedMap[indicator];
        if (matched) this.selectedFileTransactionId = matched.fileTransactionId;
      } else {
        this.selectedFileTransactionId = null;
      }
    },
  },
  methods: {
    translate(key) {
      return this.$t(`payment.paymentMatchingModal.${key}`);
    },
    handleUploadFile(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = e.target.result;
          const wb = XLSX.read(data, { type: 'binary', cellDates: true });
          var first_worksheet = wb.Sheets[wb.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(first_worksheet, {
            header: 1,
            dateNF: 'yyyy-mm-dd',
            raw: false,
            rawNumbers: true,
          });

          const fileTransactions = getFileTransactions(jsonData, this.tenantBankAccount.bankNumber);
          if (!fileTransactions) throw new Error();
          this.fileTransactions = fileTransactions;
        } catch (error) {
          this.$message.error(this.translate('uploadTransactionsFile.uploadFailed'));
          console.error(error);
        }
      };

      reader.readAsBinaryString(file);

      return false;
    },
    handleMatch() {
      if (this.UiState === STATES.MATCH) {
        const fileTransaction = this.fileTransactions.find((t) => t.id === this.selectedFileTransactionId);
        this.matchedMap = {
          ...this.matchedMap,
          [this.selectedPaymentIndicator]: {
            reference: fileTransaction.reference,
            date: DateTime.fromMillis(fileTransaction.date).toISODate(),
            fileTransactionId: this.selectedFileTransactionId,
          },
        };
      } else this.matchedMap = omit([this.selectedPaymentIndicator], this.matchedMap);

      this.selectedPaymentIndicator = null;
    },
    handleClose() {
      if (isEmpty(this.matchedMap)) this.$emit('close');
      else {
        this.$confirm(this.translate('confirm.text'), this.translate('confirm.title'), {
          confirmButtonText: this.$t('commons.apply'),
          cancelButtonText: this.$t('commons.cancel'),
          type: 'warning',
        })
          .then(() => {
            this.$emit('close');
          })
          .catch(() => {});
      }
    },
    handleSave() {
      const transformedMatchedMap = {};
      Object.entries(this.matchedMap).forEach(([key, data]) => {
        // eslint-disable-next-line no-unused-vars
        const [instructionNumber, _, paymentId] = key.split('-');

        if (paymentId.includes(instructionNumber)) {
          const paymentsWithoutSummaryGroup = this.paymentGroups[instructionNumber].filter((p) => p.id !== paymentId);
          paymentsWithoutSummaryGroup.forEach((p) => {
            transformedMatchedMap[p.id] = data;
          });
        } else {
          transformedMatchedMap[paymentId] = data;
        }
      });

      this.loading = true;
      this.$emit('match', {
        onDone: () => {
          this.matchedMap = {};
          this.$message.success(this.translate('matchingSucceeded'));
          this.$emit('close');
        },
        onError: () => {
          this.loading = false;
          this.$message.error(this.translate('matchingFailed'));
        },
        data: transformedMatchedMap,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.table-focus-shadow {
  box-shadow: 0px 0px 0px 4px $surfaces-selected;
}
.match-side {
  min-width: 48%;
}

::v-deep .file-transactions-upload {
  .el-upload {
    height: 100%;
    width: 100%;
    .el-upload-dragger {
      width: 100%;
      height: 100%;
      background: $secondary;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

::v-deep .payments-reconciliation-modal {
  height: 93vh;
  width: 98vw;

  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    color: $typography-primary;
  }

  .el-dialog__body {
    height: calc(100% - 128px);
  }
}

.action-btn {
  height: fit-content;
  &:hover {
    background: $secondary;
  }
}
</style>
